export const UPDATE_STATE = 'UPDATE_STATE';

export const RESET_STATE = 'RESET_STATE';

export const SET_APPLICATION_DATA = 'SET_APPLICATION_ID';

export const UPDATE_CUSTOMER_DETAIL = 'UPDATE_CUSTOMER_DETAIL';

export const UPDATE_RELATIONSHIPS = 'UPDATE_RELATIONSHIPS';

export const UPDATE_LOAN_PRODUCTS = 'UPDATE_LOAN_PRODUCTS';

export const START_LOADING = 'START_LOADING';

export const STOP_LOADING = 'STOP_LOADING';

export const EDIT_DEMOGRAPHIC_DETAIL = 'EDIT_DEMOGRAPHIC_DETAIL';

export const SELECT_INCOME_ACCOUNT = 'SELECT_INCOME_ACCOUNT';

export const UPDATE_LOAN_CALCULATION = 'UPDATE_LOAN_CALCULATION';

export const RESET_LOAN_CALCULATION = 'RESET_LOAN_CALCULATION';

export const UPDATE_PRE_QUALIFICATION = 'UPDATE_PRE_QUALIFICATION';

export const UPDATE_LOAN_TYPE = 'UPDATE_LOAN_TYPE';

export const UPDATE_REQUIREMENTS = 'UPDATE_REQUIREMENTS';

export const UPDATE_LOAN_ACCOUNT = 'UPDATE_LOAN_ACCOUNT';

export const UPDATE_LOAN_APPROVAL = 'UPDATE_LOAN_APPROVAL';

export const SAVE_LOAN_CALCULATION = 'SAVE_LOAN_CALCULATION';

export const RESET_LOAN_TYPE = 'RESET_LOAN_TYPE';

export const UPDATE_INDUSTRY_LIST = 'UPDATE_INDUSTRY_LIST';

export const UPDATE_SECTOR_LIST = 'UPDATE_SECTOR_LIST';

export const UPDATE_CONSENT_DATETIME = 'UPDATE_CONSENT_DATETIME';

export const SET_SERVER_DATETIME = 'SET_SERVER_DATETIME';

export const SET_TIMEOUT = 'SET_TIMEOUT';

export const SET_TOKEN = 'SET_TOKEN';