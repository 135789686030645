import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { loadUser } from 'redux-oidc';

import configureStore from './src/store/configureStore';
import userManager from './src/utils/userManager';

import 'bootstrap/dist/css/bootstrap.min.css';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = configureStore();

  if( typeof window !== `undefined`) {
    loadUser(store, userManager);

    return (<Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        {element}
      </OidcProvider>
    </Provider>);
  } else {
    return (
      <Provider store={store}>
        {element}
      </Provider>
    );
  }
}