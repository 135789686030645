import { UPDATE_STATE, START_LOADING, STOP_LOADING, UPDATE_CUSTOMER_DETAIL, UPDATE_RELATIONSHIPS, UPDATE_LOAN_PRODUCTS, EDIT_DEMOGRAPHIC_DETAIL, SELECT_INCOME_ACCOUNT, UPDATE_LOAN_APPROVAL, SET_APPLICATION_DATA, UPDATE_LOAN_CALCULATION, RESET_LOAN_CALCULATION, UPDATE_PRE_QUALIFICATION, UPDATE_LOAN_TYPE, UPDATE_REQUIREMENTS, UPDATE_LOAN_ACCOUNT, SAVE_LOAN_CALCULATION, RESET_LOAN_TYPE, UPDATE_INDUSTRY_LIST, UPDATE_SECTOR_LIST, UPDATE_CONSENT_DATETIME, SET_SERVER_DATETIME, SET_TIMEOUT, RESET_STATE, SET_TOKEN} from '../actions/actionTypes';

const loanCalculator = {
  loanAmount: null,
  term: '',
  termUnits: '',
  paymentFrequency: '',
  loanType: null,
  totalCostOfCredit: null,
  paymentsTotal: null,
  numberOfPayments: null,
  paymentAmount: null,
  interestRate: null,
  loanPurpose: ''
}

export const initialState = { 
  isLoading: false,
  loadingType: null,
  personalInfo: {},
  loanApproval: null,
  requirements: {},
  relationships: [],
  loanProducts: [],
  loanCalculator: loanCalculator,
  tempLoanCalculator: loanCalculator,
  editDemographics: false,
  allowCreditCheck: false,
  incomeAccountId: null,
  applicationId: null,
  cifId: null,
  pan: null,
  qualify: {},
  loanType: null,
  loanSummary: {},
  firstPaymentDate: null,
  termsConditionsAccepted: false,
  actingMyBehalfAccepted: false,
  understandInsurance: false,
  creditBureauConsent: null,
  loanPurpose: '',
  industryList: null,
  currentRoute: '',
  consentDatetime: null,
  serverDatetime: null,
  timeout: false,
  accessToken: null,
  isMobile: null
}

export const reducer = (state=initialState, action) => {
  switch(action.type) {
    case UPDATE_STATE: 
      return {
        ...state,
        ...action.payload
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        loadingType: action.loadingType
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
        loadingType: null
      };   
    case UPDATE_CUSTOMER_DETAIL:
      return {
        ...state,
        personalInfo: { 
          ...state.personalInfo,
          ...action.payload
        },
        editDemographics: false,        
      };
    case UPDATE_RELATIONSHIPS:
      return {
        ...state,
        relationships: action.payload
      };
    case UPDATE_LOAN_PRODUCTS:
      return {
        ...state,
        loanProducts: action.payload
      };
    case EDIT_DEMOGRAPHIC_DETAIL:
      return {
        ...state,
        editDemographics: action.edit
      };
    case SELECT_INCOME_ACCOUNT: 
      return {
        ...state,
        incomeAccountId: action.incomeAccountId
      }
    case UPDATE_LOAN_APPROVAL: 
      return {
        ...state,
        loanApproval: action.payload
      }
    case SET_APPLICATION_DATA: 
      return {
        ...state,
        applicationId: action.applicationId,
        cifId: action.cifId,
        pan: action.pan
      }
    case UPDATE_LOAN_CALCULATION:
      return {
        ...state,
        tempLoanCalculator: {
          ...state.tempLoanCalculator,
          ...action.payload,
          loanAmountChanged: false
        }
      }
    case SAVE_LOAN_CALCULATION:
      return {
        ...state,
        loanCalculator: state.tempLoanCalculator
      }
    case RESET_LOAN_CALCULATION:
      return {
        ...state,
        tempLoanCalculator: {
          ...initialState.loanCalculator
        }
      }
    case UPDATE_PRE_QUALIFICATION:
      return {
        ...state,
        qualify: action.payload
      }
    case UPDATE_LOAN_TYPE: 
      return {
        ...state,
        loanType: action.loanType
      }
    case UPDATE_REQUIREMENTS:
      return {
        ...state,
        requirements: {
          ...state.requirements,
          ...action.payload
        }
      }
    case UPDATE_LOAN_ACCOUNT: 
      return {
        ...state,
        loanSummary: action.payload
      }
    case RESET_LOAN_TYPE: 
      return {
        ...initialState,
        personalInfo: state.personalInfo,
        relationships: state.relationships,
        applicationId: state.applicationId,
        cifId: state.cifId,
        qualify: state.qualify
      }      
    case UPDATE_INDUSTRY_LIST: 
      return {
        ...state,
        industryList: action.payload
      }
    case UPDATE_SECTOR_LIST: 
      const industryList = [...state.industryList.filter(industry => industry.Key !== action.industry),
        { ...state.industryList.find(industry => industry.Key === action.industry),
          sectors: action.payload
        }];

      return {
        ...state,
        industryList: industryList.sort((a, b) => (a.Key > b.Key) ? 1 : ((b.Key > a.Key) ? -1 : 0))
      }
    case UPDATE_CONSENT_DATETIME: 
      return {
        ...state,
        consentDatetime: action.payload
      }
    case SET_SERVER_DATETIME: 
      return {
        ...state,
        serverDatetime: action.payload
      }
    case SET_TIMEOUT: 
      return {
        ...state,
        timeout: true
      }
    case SET_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }
    case RESET_STATE: 
      return {
        ...initialState
      }
    default: 
      return state;      
  }
}