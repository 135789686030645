import { createUserManager } from 'redux-oidc';
import { globalHistory } from '@reach/router';

const userManagerConfig = {
  client_id: 'vancity',
  redirect_uri: `${globalHistory.location.protocol}//${globalHistory.location.hostname}${globalHistory.location.port ? `:${globalHistory.location.port}` : ''}/callback`,
  response_type: 'id_token token',
  scope: 'openid profile banking email phone address',
  authority: 'http://localhost:8080/oidc-webapp',
  silent_redirect_uri: `${globalHistory.location.protocol}//${globalHistory.location.hostname}${globalHistory.location.port ? `:${globalHistory.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

let userManager = null;

if (typeof window !== `undefined`) {
  userManager = createUserManager(userManagerConfig);
}

export default userManager;
